import { Spin } from "antd"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import InfiniteScroll from "react-infinite-scroller"

import JumbotronComponent from "@components/Jumbotron"
import Layout from "@components/Layout"
import LocalizedLink from "@components/LocalizedLink"
import SEO from "@components/SEO"
import Subheading from "@components/Subheading"

const NewsSectionCSS = styled.div`
  width: 70vw;
  margin: 0 auto;

  padding-top: 50px;

  a {
    color: black;
  }

  .news-entries {
    width: 80%;
    margin: 0 auto;

    padding-bottom: 1rem;
  }

  .entry-title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .entry-date {
    color: gray;
  }

  .entry-description {
    font-size: 1rem;
    color: #777;
  }
`

const size = 10


const InfiniteArticleList = ({ articles = [] }) => {
  const [data, setData] = useState(articles.slice(0, size))
  const [counter, setCounter] = useState(size)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(false)
    if (data.length === articles.length) {
      setHasMore(false)
    }
    setData(data => [...data, ...articles.slice((counter), (counter + 2 * size))])
    setCounter(counter => counter + 2 * size)
    setLoading(true)
  }

  const { i18n } = useTranslation()

  return (
    <>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={fetchData}
        hasMore={!loading && hasMore}
        loader={
          loading && hasMore && (
            <div className="demo-loading-container">
              <Spin loading={loading} />
            </div>
          )
        }
      >
        {
          data && data.map((entry, index) => {
            return (entry.node.fields.langKey === i18n.language &&
              <LocalizedLink key={index} language={i18n.language} to={entry.node.fields.slug}>
                <div className="news-entries">
                  <div className="entry-title">
                    {entry.node.frontmatter.title}
                  </div>
                  <div className="entry-description">
                    {entry.node.frontmatter.description}
                  </div>
                </div>
              </LocalizedLink>
            )
          })
        }
      </InfiniteScroll>
    </>
  )
}


const NewsSection = ({ t, data }) => {
  const { allMarkdownRemark: { edges: articles } } = data
  return (
    <NewsSectionCSS>
      <Subheading text={t("news")} />
      <InfiniteArticleList articles={articles} />
    </NewsSectionCSS >
  )
}

const MediaPage = ({ location, data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <JumbotronComponent t={t} imgUrl="/img/media_jumbotron.jpg" isJumbotron />
      <NewsSection t={t} data={data} />
    </Layout>
  )
}

MediaPage.propTypes = {
  location: PropTypes.object.isRequired
}

export default MediaPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["media","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: { frontmatter: { templateKey: { eq: "news-article" } } }) {
            edges {
                node {
                    frontmatter {
                        title
                        templateKey
                        date(formatString: "MMM DD，YYYY")
                    }
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        langKey
                        slug
                    }
                }
            }
        }
    }
    
`

